
import cmsComponent from '~/mixins/cmsComponent';
import GFullWidthCarousel from './goose/g-full-width-carousel';

export default {
  name: 'cms-full-width-carousel',
  components: { GFullWidthCarousel },
  mixins: [cmsComponent],
  computed: {
    fullWithCarouselProps() {
      return {
        theme: this.blok.theme,
        carousels: this.blok.carousels,
      };
    },
  },
};
